import React, { useState, useEffect } from 'react';

const RealEstateMap = () => {
  const [map, setMap] = useState(null);
  const [selectedProperty, setSelectedProperty] = useState(null);
  const [isPopupVisible, setIsPopupVisible] = useState(false);
  
  // Sample property data
  const properties = [
    {
      listing_type: "sale",
      price: 527900,
      bedrooms: 4,
      bathrooms: 3,
      size_sqft: 2246,
      address: {
        street: "7292 Nautica Way",
        city: "Lake Worth",
        state: "FL",
        zip_code: "33467",
        country: "USA",
        latitude: 26.62806484321901,
        longitude: -80.12410721025458
      },
      pictures: [
        "static/Images/1/1.jpg",
        "static/Images/1/2.jpg",
        "static/Images/1/3.jpg",
      ]
    },
    {
      "listing_type": "sale",
      "price": 450000,
      "bedrooms": 3,
      "bathrooms": 2,
      "size_sqft": 1800,
      "address": {
        "street": "1234 Ocean Drive",
        "city": "Miami Beach",
        "state": "FL",
        "zip_code": "33139",
        "country": "USA",
        "latitude": 26.635878,
        "longitude":-80.077197
        
      },
      "pictures": [
        "static/Images/1/1.jpg",
        "static/Images/1/2.jpg",
        "static/Images/1/3.jpg",
      ]
    },
    {
      "listing_type": "rent",
      "price": 850,
      //price per month
      "bedrooms": 5,
      "bathrooms": 4,
      "size_sqft": 3000,
      "address": {
        "street": "5678 Palm Avenue",
        "city": "Fort Lauderdale",
        "state": "FL",
        "zip_code": "33301",
        "country": "USA",
        "latitude": 26.618608,
        "longitude":-80.056089
        
      },
      "pictures": [
        "static/Images/1/1.jpg",
        "static/Images/1/2.jpg",
        "static/Images/1/3.jpg",
      ]
    }
  ];

  // Format price with commas
  const formatPrice = (price) => {
    return price.toLocaleString('en-US', {
      style: 'currency',
      currency: 'USD',
      maximumFractionDigits: 0
    });
  };

  useEffect(() => {
    const loadGoogleMapsScript = () => {
      const script = document.createElement('script');
      script.src = `https://maps.googleapis.com/maps/api/js?key=AIzaSyDkIKfrBfuZy-DkZGY8nZFqnDDDxNIVKoc`;
      script.async = true;
      script.defer = true;
      script.onload = initializeMap;
      document.body.appendChild(script);
    };

    const initializeMap = () => {
      const mapInstance = new window.google.maps.Map(document.getElementById('map'), {
        center: { 
          lat: properties[0].address.latitude, 
          lng: properties[0].address.longitude 
        },
        zoom: 15,
        styles: [
          {
            featureType: "poi",
            elementType: "labels",
            stylers: [{ visibility: "off" }]
          }
        ]
      });

      setMap(mapInstance);

      properties.forEach(property => {
        const marker = new window.google.maps.Marker({
          position: { 
            lat: property.address.latitude, 
            lng: property.address.longitude 
          },
          map: mapInstance,
          title: property.address.street,
          animation: window.google.maps.Animation.DROP
        });

        marker.addListener('click', () => {
          setSelectedProperty(property);
          setIsPopupVisible(true);
          marker.setAnimation(window.google.maps.Animation.BOUNCE);
          setTimeout(() => marker.setAnimation(null), 750);
        });
      });
    };

    loadGoogleMapsScript();
  }, []);

  const handlePopupClose = () => {
    setIsPopupVisible(false);
    setTimeout(() => setSelectedProperty(null), 300);
  };

  return (
    <div style={{ position: 'relative', width: '100%', height: '100vh' }}>
      <div id="map" style={{ width: '100%', height: '100%' }} />
      
      {selectedProperty && (
        <div 
          style={{
            position: 'fixed',
            top: 0,
            left: 0,
            right: 0,
            bottom: 0,
            backgroundColor: 'rgba(0, 0, 0, 0.7)',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            zIndex: 1000,
            opacity: isPopupVisible ? 1 : 0,
            transition: 'opacity 0.3s ease-in-out',
            backdropFilter: 'blur(5px)'
          }}
          onClick={handlePopupClose}
        >
          <div 
            style={{
              backgroundColor: 'white',
              borderRadius: '16px',
              maxWidth: '600px',
              width: '90%',
              position: 'relative',
              overflow: 'hidden',
              boxShadow: '0 20px 40px rgba(0, 0, 0, 0.2)',
              transform: isPopupVisible ? 'scale(1) translateY(0)' : 'scale(0.9) translateY(20px)',
              transition: 'transform 0.3s cubic-bezier(0.34, 1.56, 0.64, 1)',
              opacity: isPopupVisible ? 1 : 0
            }}
            onClick={(e) => e.stopPropagation()}
          >
            <div style={{ position: 'relative' }}>
              <img
                src={selectedProperty.pictures[0]}
                alt="Property"
                style={{
                  width: '100%',
                  height: '300px',
                  objectFit: 'cover',
                  display: 'block'
                }}
                onError={(e) => {
                  e.target.src = 'static/Images/1/1.jpg';
                }}
              />
              <div style={{
                position: 'absolute',
                top: '20px',
                left: '20px',
                backgroundColor: selectedProperty.listing_type === 'sale' ? '#2563eb' : '#16a34a',
                color: 'white',
                padding: '6px 12px',
                borderRadius: '20px',
                fontSize: '14px',
                fontWeight: '500',
                textTransform: 'capitalize'
              }}>
                For {selectedProperty.listing_type}
              </div>
              <button
                style={{
                  position: 'absolute',
                  right: '15px',
                  top: '15px',
                  background: 'rgba(255, 255, 255, 0.9)',
                  border: 'none',
                  width: '36px',
                  height: '36px',
                  borderRadius: '50%',
                  cursor: 'pointer',
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'center',
                  fontSize: '20px',
                  color: '#333',
                  boxShadow: '0 2px 6px rgba(0, 0, 0, 0.1)',
                  transition: 'transform 0.2s'
                }}
                onClick={handlePopupClose}
                onMouseOver={(e) => e.target.style.transform = 'scale(1.1)'}
                onMouseOut={(e) => e.target.style.transform = 'scale(1)'}
              >
                ×
              </button>
            </div>

            <div style={{ padding: '24px' }}>
              <h2 style={{ 
                margin: '0 0 12px 0',
                color: '#333',
                fontSize: '28px',
                fontWeight: '600'
              }}>
                {formatPrice(selectedProperty.price)}
              </h2>
              
              <p style={{
                margin: '0 0 20px 0',
                color: '#666',
                fontSize: '16px'
              }}>
                {selectedProperty.address.street}<br />
                {selectedProperty.address.city}, {selectedProperty.address.state} {selectedProperty.address.zip_code}
              </p>

              <div style={{
                display: 'grid',
                gridTemplateColumns: 'repeat(3, 1fr)',
                gap: '16px',
                marginBottom: '20px',
                textAlign: 'center'
              }}>
                <div style={{
                  padding: '12px',
                  backgroundColor: '#f8f9fa',
                  borderRadius: '12px'
                }}>
                  <div style={{ fontSize: '20px', fontWeight: '600', color: '#333' }}>
                    {selectedProperty.bedrooms}
                  </div>
                  <div style={{ fontSize: '14px', color: '#666' }}>Bedrooms</div>
                </div>
                <div style={{
                  padding: '12px',
                  backgroundColor: '#f8f9fa',
                  borderRadius: '12px'
                }}>
                  <div style={{ fontSize: '20px', fontWeight: '600', color: '#333' }}>
                    {selectedProperty.bathrooms}
                  </div>
                  <div style={{ fontSize: '14px', color: '#666' }}>Bathrooms</div>
                </div>
                <div style={{
                  padding: '12px',
                  backgroundColor: '#f8f9fa',
                  borderRadius: '12px'
                }}>
                  <div style={{ fontSize: '20px', fontWeight: '600', color: '#333' }}>
                    {selectedProperty.size_sqft.toLocaleString()}
                  </div>
                  <div style={{ fontSize: '14px', color: '#666' }}>Sq Ft</div>
                </div>
              </div>

              <div style={{
                padding: '15px',
                backgroundColor: '#f8f9fa',
                borderRadius: '12px',
                fontSize: '14px',
                color: '#666'
              }}>
                <div style={{ marginBottom: '8px' }}>
                  📍 Property Location:
                </div>
                <div>
                  Latitude: {selectedProperty.address.latitude.toFixed(6)}<br />
                  Longitude: {selectedProperty.address.longitude.toFixed(6)}
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default RealEstateMap;